import LockIcon from "@material-ui/icons/Lock";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import LocalLibraryOutlinedIcon from "@material-ui/icons/LocalLibraryOutlined";
import BusinessIcon from "@material-ui/icons/Business";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import React from "react";

export const swaggerLinks = [
  {
    icon: <LockIcon />,
    id: "Functions.Auth",
    name: "Auth",
    version: "v2",
    displayName: "Auth",
    swaggerUrl: "./specs/auth.json",
    default: true,
    items: [
      {
        id: "authentication/token",
        name: "Get Token",
        displayName: "Get Token",
        swaggerUrl: "post-/authentication/token",
      },
    ],
  },
  {
    icon: <LocalLibraryOutlinedIcon />,
    id: "Functions.Products",
    name: "Products",
    displayName: "Products",
    version: "v1",
    swaggerUrl: "./specs/products.json",
    items: [
      {
        id: "products/pricing",
        name: "Pricing",
        displayName: "Pricing",
        swaggerUrl: "post-/products/pricing",
      },
    ],
  },
  {
    icon: <LocalLibraryOutlinedIcon />,
    id: "Functions.Products",
    name: "v2/Products",
    displayName: "Products",
    version: "v2",
    swaggerUrl: "./specs/products.json",
    items: [
      {
        id: "products/v2/catalog",
        name: "Catalog",
        displayName: "Catalog",
        swaggerUrl: "get-/products/v2/catalog",
      },
      {
        id: "products/v2/byItemCodes",
        name: "By Item Codes",
        displayName: "By Item Codes",
        swaggerUrl: "post-/products/v2/catalog/-branchCode-/byItemCodes",
      },
      {
        id: "products/v2/price",
        name: "Price",
        displayName: "Price",
        swaggerUrl: "post-/products/v2/price",
      },
      {
        id: "products/v2/catalogConvert",
        name: "Catalog Items Convert",
        displayName: "Catalog Items Convert",
        swaggerUrl: "post-/products/v2/catalog/convert",
      },
      {
        id: "products/v2/colorRecommendations",
        name: "Color Recommendations",
        displayName: "Color Recommendations",
        swaggerUrl: "post-/products/v2/colorRecommendations",
      }
    ],
  },
  {
    icon: <AssignmentTurnedInIcon />,
    id: "Functions.Orders",
    name: "Orders",
    displayName: "Orders",
    version: "v1",
    swaggerUrl: "./specs/orders.json",
    items: [
      {
        id: "order/submit",
        name: "Submit Order",
        displayName: "Submit Order",
        swaggerUrl: "post-/orders/submit",
      }
    ],
  },
  {
    icon: <AssignmentTurnedInIcon />,
    id: "Functions.Deliveries",
    name: "v2/Deliveries",
    displayName: "Deliveries",
    version: "v2",
    swaggerUrl: "./specs/deliveries.json",
    items: [
      {
        id: "get-/deliveries/v2/-orderNumber-/coordinates",
        name: "Coordinates",
        displayName: "Coordinates",
        swaggerUrl: "get-/deliveries/v2/-orderNumber-/coordinates",
      },
    ],
  },
  {
    icon: <DescriptionOutlinedIcon />,
    id: "Functions.Branches",
    name: "Branches",
    displayName: "Branches",
    version: "v1",
    swaggerUrl: "./specs/branches.json",
    items: [
      {
        id: "branches/{branchId}",
        name: "Branch Details",
        displayName: "Branch Details",
        swaggerUrl: "get-/branches/-branchId-",
      },
      {
        id: "branches/{branchId}/ActiveItems",
        name: "Branch Active Items",
        displayName: "Branch Active Items",
        swaggerUrl: "get-/branches/-branchId-/ActiveItems",
      },
    ],
  },
  {
    icon: <DescriptionOutlinedIcon />,
    id: "Functions.Branches",
    name: "v2/Branches",
    displayName: "Branches",
    version: "v2",
    swaggerUrl: "./specs/branches.json",
    items: [
      {
        id: "branches/{branchId}",
        name: "Branch Locations",
        displayName: "Branch Locations",
        swaggerUrl: "get-/branches/v2/branchLocations",
      },
      {
        id: "branches/{branchId}/ActiveItems",
        name: "Active Branch Products",
        displayName: "Active Branch Products",
        swaggerUrl: "get-/branches/v2/activeBranchProducts/-branchCode-",
      },
      {
        id: "get-/api/v2/customerBranchLocations/-customerCode-",
        name: "Customer Branch Locations",
        displayName: "Customer Branch Locations",
        swaggerUrl: "get-/branches/v2/customerBranchLocations/-customerCode-",
      },
    ],
  },
  {
    icon: <BusinessIcon />,
    id: "Functions.Customers",
    name: "Customers",
    displayName: "Customers",
    version: "v1",
    swaggerUrl: "./specs/customers.json",
    items: [
      {
        id: "get-/customers/-customerCode-/NearestEligibleBranch",
        name: "Nearest Eligible Branch",
        displayName: "Nearest Eligible Branch",
        swaggerUrl: "get-/customers/-customerCode-/NearestEligibleBranch",
      },
      {
        id: "get-/customers/-customerCode-/shipToAddresses/-branchCode-",
        name: "Ship to Adresses",
        displayName: "Ship to Adresses",
        swaggerUrl:
          "get-/customers/-customerCode-/shipToAddresses/-branchCode-",
      },
      {
        id: "get-/customers/-customerCode-/orderTemplates/-branchCode-",
        name: "Order Templates",
        displayName: "Order Templates",
        swaggerUrl: "get-/customers/-customerCode-/orderTemplates/-branchCode-",
      }
    ],
  },
  {
    icon: <BusinessIcon />,
    id: "Functions.Customers",
    name: "v2/Customers",
    displayName: "Customers",
    version: "v2",
    swaggerUrl: "./specs/customers.json",
    items: [
      {
        id: "get-/api/v2/orderTemplates/-customerCode-",
        name: "Order Templates",
        displayName: "Order Templates",
        swaggerUrl: "get-/customers/v2/orderTemplates/-customerCode-",
      },
      {
        id: "get-/customers/-customerCode-",
        name: "Details",
        displayName: "Details",
        swaggerUrl: "get-/customers/-customerCode-",
      },
      {
        id: "get-/customers/validate",
        name: "Validate Customer",
        displayName: "Validate Customer",
        swaggerUrl: "get-/customers/validate",
      }
    ],
  },
  {
    icon: <LocalLibraryOutlinedIcon />,
    id: "Functions.Orders",
    name: "v2/Orders",
    displayName: "Orders",
    version: "v2",
    swaggerUrl: "./specs/orders.json",
    items: [
      
      {
        id: "get-/deliveries/-orderNumber-",
        name: "Delivery Details",
        displayName: "Delivery Details",
        swaggerUrl: "get-/deliveries/-orderNumber-",
      },
      {
        id: "delivery/list",
        name: "Delivery List",
        displayName: "Delivery List",
        swaggerUrl: "post-/deliveries/list",
      },
      {
        id: "delivery/ProofOfDelivery",
        name: "Proof of Delivery",
        displayName: "Proof of Delivery",
        swaggerUrl: "get-/deliveries/-orderNumber-/ProofOfDelivery",
      },
      {
        id: "order/orderNumber",
        name: "Order Details",
        displayName: "Order Details",
        swaggerUrl: "get-/orders/-orderNumber-",
      },
      {
        id: "orders/list",
        name: "Orders List",
        displayName: "Orders List",
        swaggerUrl: "post-/orders/list",
      },
      {
        id: "order/v2/submit",
        name: "Submit Order",
        displayName: "Submit Order",
        swaggerUrl: "post-/orders/v2/submit",
      },
    ],
  },
  {
    icon: <DescriptionOutlinedIcon />,
    id: "Getting.Started",
    name: "Quick-Start",
    version: "gs",
    displayName: "Quick Start",
    swaggerUrl: "./gs/specs/gettingstarted.json",
    default: true
  },
  {
    icon: <LockIcon />,
    id: "Getting.Started",
    name: "Web-Hooks",
    version: "gs",
    displayName: "Web Hooks",
    swaggerUrl: "./gs/specs/webhooks.json",
    default: true
  },
  {
    icon: <LocalLibraryOutlinedIcon />,
    id: "Getting.Started",
    name: "Faqs",
    version: "gs",
    displayName: "FAQs",
    swaggerUrl: "./gs/specs/faq.json",
    default: true
  },
  {
    icon: <AssignmentTurnedInIcon />,
    id: "Functions.Invoices",
    name: "Invoices",
    displayName: "Invoices",
    version: "v2",
    swaggerUrl: "./specs/invoices.json",
    items: [
      {
        id: "/invoices/invoiceNumber",
        name: "Invoice Details",
        displayName: "Invoice Details",
        swaggerUrl: "get-/invoices/-invoiceNumber-",
      },
      {
        id: "/invoices/list",
        name: "Invoices List",
        displayName: "Invoices List",
        swaggerUrl: "post-/invoices/list",
      },
      {
        id: "/invoices/invoiceNumber/PDF",
        name: "Invoice PDF",
        displayName: "Invoice PDF",
        swaggerUrl: "get-/invoices/-invoiceNumber-/PDF",
      }
    ],
  }
];
