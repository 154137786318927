import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import OrgConfig from "../../config/organization_config.json";
import { swaggerLinks } from "../../config/specs";
import Sidebar from "../Sidebar";
import AppRapiDoc from "./rapiDoc";
import { getDefaultAPILink, getApiLinkByName } from "../../helpers/app";

const App = (props) => {
  const DEFAULT_PATH = "/";
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, hash } = location;
  const defaultLink =
    pathname === DEFAULT_PATH
      ? getDefaultAPILink(swaggerLinks)
      : getApiLinkByName(swaggerLinks, pathname);
  const [organizationConfig] = useState(OrgConfig.orgData);
  const [definitionLink, setDefinitionLink] = useState(defaultLink);
  const [selected, setSelected] = useState(defaultLink);
  const [hashState, setHash] = useState(hash);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { name } = definitionLink;
    if (definitionLink === selected) {
      navigate(
        `${name}${
          pathname === `/${name}` || pathname === DEFAULT_PATH ? hash : ""
        }`
      );
      setLoading(true);
    } else {
      const { swaggerUrl } = selected;
      //This would be for adding into the url the details about a specific endpoint
      navigate(`${name}#${swaggerUrl}`);
    }
  }, [definitionLink, selected]);

  useEffect(() => {
    setLoading(true);
  }, [hash]);

  useEffect(() => {
    if (loading) {
      setLoading(false);
      setHash(hash);
    }
  }, [loading]);

  const updateDefinitionLink = (element) => {
    setDefinitionLink(element);
    setSelected(element);
  };

  const updateChildSelectionLink = (parent, child) => {
    setDefinitionLink(parent);
    setSelected(child);
  };

  return (
    <div className="App">
      <Sidebar
        organizationConfig={organizationConfig}
        updateDefinitionLink={updateDefinitionLink}
        updateChildSelectionLink={updateChildSelectionLink}
        swaggerLinks={swaggerLinks}
        selected={selected}
      />
      {loading ? (
        <span>loading</span>
      ) : (
        <AppRapiDoc definitionLink={definitionLink} hashState={hashState} />
      )}
    </div>
  );
};

export default App;
