import React, { useState } from "react";
import APILink from "./APILink.js";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  menuAccordion: {
    backgroundColor: "transparent",
    color: "#FFFFFF",
    boxShadow: "none",
  },
}));

const Sidebar = (props) => {
  const {
    organizationConfig,
    updateDefinitionLink,
    swaggerLinks,
    selected,
    updateChildSelectionLink,
  } = props;
  const apiLinks = {
    v1: [],
    v2: [],
    gs: []
  };
  const classes = useStyles();
  const [apiVersion, setVersion] = useState("v1");

  swaggerLinks.forEach((element) => {
    const { id, version } = element;
    apiLinks[version].push(
      <APILink
        key={id}
        element={element}
        updateDefinitionLink={updateDefinitionLink}
        updateChildSelectionLink={updateChildSelectionLink}
        selected={selected}
      />
    );
  });

  return (
    <div className="side-bar">
      <div className="side-bar-header">
        <img src={organizationConfig.displayImage} alt="logo" />
      </div>
      <div className="side-bar-body">
      <Accordion className={classes.menuAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              <h4>GETTING STARTED</h4>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{apiLinks["gs"]}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.menuAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              <h4>INTEGRATION HUB API</h4>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{apiLinks["v2"]}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.menuAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              <h4>LEGACY API</h4>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{apiLinks["v1"]}</Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default Sidebar;
